import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/kito.png';
import tobori from '../images/ob/higashi20241.png';
import toboriImg1 from '../images/ob/higashi20242.png';
import toboriImg2 from '../images/ob/higashi20243.png';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>
class ManabePage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>OBインタビュー　東宏輝|國學院大學久我山高等学校　バスケットボール部</title>
        <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 鬼頭佑育</h4>
          <div className="mng-text">
本日は、お忙しい中、インタビューに応じて下さり、ありがとうございます。久我山高校バスケ部の鬼頭と申します。本日は、どうぞよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <h4 className="vertical-timeline-element-subtitle">東宏輝</h4>
          <div className="ob-text">
          よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            まず、自己紹介からお願いします！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          現在、富山グラウジーズでプレーをしています。東宏輝です。國學院久我山高校卒業後、名古屋学院大学で4年間プレーした後、プロバスケットボール選手になりました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
ありがとうございます。では、早速、國學院久我山高校への入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          僕が1番魅力的に思ったのは”文武両道”であることですね。もともと國學院久我山高校が文武両道の学校であることは知っていて、バスケットだけやっている高校に当時は行こうと思えばいけたんですけど、将来のために”勉強”も”バスケット”もどちらも一生懸命頑張る國學院久我山高校を選びました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。僕も久我山は文武両道の学校なので東さんと同じ理由で選びました！文武両道の実践において、勉強とバスケットを両立するために何か工夫などされていましたか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね、なかなか帰ってからだと勉強の時間がとりにくいという事もあるので、家が遠かった事もあり、僕は電車での移動時間うまく使いました。電車に乗ってる間、古文単語や英単語など小テストに向けて勉強をしていました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          家が遠い人は移動時間が長い分、その時間を勉強にあてることが大切なのですね！ では、次の質問に移らせていただきます。文武両道以外で久我山の魅力的な点はありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね、僕はスポーツクラスにいたので、そこで感じたのは”横のつながり”があることですね。サッカー、バスケ、ラグビー、陸上、野球という５つの競技でどれも全国レベルの選手が揃う中、同じクラスで3年間生活できるのは久我山ならではの貴重な経験ですね。今でいうとプロになっている人もたくさんいるので、そう言った意味でのつながりはとても魅力的でした。あとは、それでいてみんな勉強ができるということですね。勉強もバスケットも志が高い生徒が集まるので、とても質の高い生活を3年間送ることができました。
        </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obpageImg" alt="manabekei" src={toboriImg1} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。ここまで勉強もスポーツもレベルの高い人が揃うのは全国的に見ても久我山の魅力だと僕も思います。では、今から國學院久我山高校に入るつもりの中学生の中にも、将来、東さんのようにBリーガーになりたい！と思っている人はたくさんいると思うのですが、Bリーガーになるためにどんなことが必要なことはなんでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          何かに突出することは大事ですね。もちろん、ディフェンスが上手でチームに呼ばれる選手もいますし、オフェンスでみるとシュートが上手かったり、ゲームメイクが上手だったり、そうやって何かに突出している選手が、どこのチームにも集まっているので、１つ言うのであれば”何か秀でる”と言うことは大切ですね。
たとえば、アウトサイドが上手な選手が欲しいとなった時に、同じ3Pの確率の選手が2人、3人いたとします。そうなった時に、もう一つ”人間性”と言うところも大事になってきます。プロも一つの組織なので、自分のプレーだけの選手はやっぱりプロにはなりづらいですね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          なるほど。チームスポーツなので、やはり「人間性」はプロになるための大切な要素のひとつなのですね！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          めちゃくちゃ大事です！
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obpageImg" alt="manabekei" src={toboriImg2} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、最後に國學院久我山高校に入学希望のある中学生とその保護者に向けてメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          他の高校と國學院久我山と迷ったら真っ先に久我山をお勧めします！やはり先ほど言った魅力もありますし、本当に規則正しい学校で人間として成長できる、しかも文武両道で大学や社会人になっても通用する人として、いい人間が育ち、成長できる3年間になりますね！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本日はお忙しい中ありがとうございました。これからも久我山バスケ部の応援をよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          はい。ありがとうございました。
        </div>
        </VerticalTimelineElement>


      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <ManabePage />
  </Layout>
);
